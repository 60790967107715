<template>
    <div>
        <news-detail-pc v-if="$responsive.is('pc')"></news-detail-pc>
        <news-detail-mobile v-if="$responsive.is('mobile')"></news-detail-mobile>
    </div>
</template>


<script>
    import NewsDetailPC from './news-detail-pc';
    import NewsDetailMobile from './news-detail-mobile';

    export default {
      components: {
        'news-detail-pc': NewsDetailPC,
        'news-detail-mobile': NewsDetailMobile,
      },
    }
</script>